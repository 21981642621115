<template>
    <div>
        <v-icon small
                class="mr-2"
                @click="updatePermission"
                :disabled="dataDelete.length > 0"
                v-if="checkUserPermission('EDIT')"
                >mdi-pencil
        </v-icon>
        <v-icon color="red"
                small
                @click="dialogDelete = true"
                :disabled="dataDelete.length > 0"
                v-if="checkUserPermission('DELETE')"
        >mdi-delete</v-icon>

    <dialog-delete :darkmode="darkmode"
                :dialog-delete="dialogDelete"
                v-on:closeDelete="cancelDelete"
                v-on:deleteItemConfirm="deleteItem"
    />
    </div>
</template>

<script>
import { mapActions } from "vuex";
import DialogDelete from "../../../../components/shared/notify/DialogDelete";
export default {
  components: {DialogDelete},
  props: ['darkmode', "item", "params", "dataDelete", "userPermission"],
    data(){
        return {
            dialogDelete: false,
        };
    },

    methods:{
      async updatePermission(){
        await this.fetchCurrentPermission(this.item);
        await this.fetchPopUp(true);
      },

      cancelDelete() {
        this.dialogDelete = false
      },

    async deleteItem() {
      await this.deletePermission({
        vm: this,
        data: {deleteItems: [{_id: this.item._id}]}
      });
      await this.fetchListPermission()
      this.dialogDelete = false;
    },

    ...mapActions("$_permission",["fetchListPermission","fetchPopUp","fetchCurrentPermission", "deletePermission"]),

    },
}
</script>

<style lang="scss" scoped>

</style>
